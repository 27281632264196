<template>
    <div>
        <div class="table__spinnerWrapper" v-if="driverRoute.routeInfoState.loading">
            <app-spinner></app-spinner>
        </div>
        <div v-else>
            <v-row class="py-10 px-10 d-flex justify-space-between align-center">
                <div>
                    <h4> {{ $t('route name') }}</h4>
                    <h2>{{ getData.name }}</h2>
                </div>
                <div>
                    <h4> {{ $t('route price') }}</h4>
                    <h2>{{ new Intl.NumberFormat().format(getData.price) }}</h2>
                </div>
                <div>
                    <h4> {{ $t('driver name') }}</h4>
                    <h2>{{ getData.driver.name }}</h2>
                </div>
                <div>
                    <h4> {{ $t('driver number') }}</h4>
                    <h2>{{ getData.driver.phone }}</h2>
                </div>
            </v-row>
            <v-row></v-row>
            <v-divider></v-divider>
            <template>
                <v-tabs v-model="tab" fixed-tabs >
                    <v-tab href="#tab-1">
                        {{ $t('student in this route') }}
                    </v-tab>
                    <v-tab href="#tab-2">
                        {{ $t('Trips') }}
                    </v-tab>
                </v-tabs>
                <v-divider></v-divider>
                <v-tabs-items v-model="tab">
                    <v-tab-item :value="'tab-1'">
                        <v-spacer></v-spacer>
                        <v-row>
                            <v-col md="1"><v-spacer></v-spacer></v-col>

                            <v-col>
                                <students-filter @setDataFilter="setDataFilter($event)"></students-filter>
                            </v-col>
                            <v-col md="1"><v-spacer></v-spacer></v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-card elevation="0" class="pt-5">
                                    <routes-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
                                        @re-fetch-paginated-data="fetchData($event)">
                                    </routes-table>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-tab-item>
                    <v-tab-item v-if="tab == 'tab-2'" :value="'tab-2'">
                        <trips></trips>
                    </v-tab-item>
                </v-tabs-items>
            </template>
        </div>
    </div>
</template>
<script>
import { driverRoute } from '../../../store/driverRoutes'
import routesTable from '../../../components/base/studentRouteTable.vue'
import studentsFilter from '../modules/students/studentsFilter.vue'
import trips from './Trips.vue'
export default {
    components: {
        routesTable,
        studentsFilter,
        trips
    },
    data() {
        return {
            tab: null,
            driverRoute,
            routeId: this.$route.params.routeId,
            params: {
                page: 1,
                route_id: this.$route.params.routeId
            },
            dataFilter: {
                name: null,
                type: null,
                gender: null,
            },
            data: {
                name: null,
                price: null,
                driver: {
                    name: null,
                    phone: null
                }
            }
        }

    },
    computed: {
        getData() {
            return driverRoute.routeInfoState.value.data
        },
        tableOptions() {
            return driverRoute.studentsRouteTable
        },
    

    },
    created(){
        console.log(this.routeId);
    },
    methods: {
        fetchData(page) {
            let params = {
                page: page,
                name: this.dataFilter.name,
                gender: this.dataFilter.gender,
                type: this.dataFilter.type,
                route_id: this.routeId,
            }
            driverRoute.getStudentsInRoute(params)
        },
        setDataFilter(data) {
            this.dataFilter = data
            this.routeId =  this.$route.params.routeId
            this.fetchData(1)
        }
    },
    mounted() {
        
        this.routeId =  this.$route.params.routeId
        driverRoute.getRouteInfo(this.routeId)
        this.fetchData(1)


    }
}
</script>