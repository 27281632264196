<template>
    <div>
        <v-row>
            <v-col>
                <v-card elevation="0" class="pt-5">
                    <routes-table :tableOptions="tableOptions" @re-fetch-data="fetchData"
                        @re-fetch-paginated-data="fetchData($event)">
                    </routes-table>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>
<script>
import routesTable from '../../../components/base/tripsTable.vue'
import { driverRoute } from '../../../store/driverRoutes'
export default {
    components: {
        routesTable
    },
    data() {
        return {
            driverRoute,
            route_id: this.$route.params.routeId
        }
    },
    methods: {
        fetchData(page) {
            let params = {
                page: page,
                route_id: this.route_id
            }
            driverRoute.getTrips(params)
        },
    },
    computed: {
        tableOptions() {
            return driverRoute.tripsTable
        }
    },
    mounted() {
        this.fetchData(1)
    }
}
</script>